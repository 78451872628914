import { ButtonLink, Header, Stack } from '@asgard/ui-library';
import { faUserAstronaut } from '@fortawesome/pro-light-svg-icons';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { PageContentContainer } from 'components/layout/Page.styled';
import { useI18n } from 'hooks/useI18n';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { getPublicPath } from 'utils/paths';
import { CustomersItem, HomeV2HeroProps } from '../HomeV2Page.types';
import { Hero } from './HomeV2Hero.styled';

export const HomeV2Hero: React.FC<HomeV2HeroProps> = ({ customers }) => {
  const { t } = useI18n('home-v2');
  const { locale } = useRouter();

  const filteredRow = (e: number): CustomersItem[] => {
    return customers.filter((el: CustomersItem) => {
      if (el.attributes.row === e) {
        return el;
      }
    });
  };

  return (
    <Hero.Root>
      <PageContentContainer containerSize="xlMax">
        <Hero.Wrapper>
          <Stack direction="column" gap="3xl">
            <Stack direction="column-reverse" className="titles">
              <Header editorial as="h2" size="5xl">
                {t('home-v2.hero.title')}
              </Header>
              <p>{t('home-v2.hero.subtitle')}</p>
            </Stack>

            <Stack contentAlign="center">
              <Link href={getAppUrl(APP_URLS.companies, locale)} passHref>
                <ButtonLink
                  schema="brand1"
                  variant="hard"
                  startIcon={faBuilding}
                  size="s"
                  label={t('home-v2.hero.cta-company')}
                />
              </Link>

              <Link href={getAppUrl(APP_URLS.talent, locale)} passHref>
                <ButtonLink
                  schema="brand1"
                  variant="ghost"
                  size="s"
                  startIcon={faUserAstronaut}
                  label={t('home-v2.hero.cta-candidate')}
                />
              </Link>
            </Stack>

            <img
              src={getPublicPath(
                '/images/home-v2/home-v2-hero-screenshot.webp'
              )}
              alt=""
              width="904"
              height="571"
            />
          </Stack>
        </Hero.Wrapper>

        <Hero.Customers>
          <Stack direction="column" gap="xl">
            <h3>{t('home-v2.hero.work-with-us')}</h3>

            <Hero.StyledLogos>
              <div>
                {filteredRow(1)
                  .sort(
                    (a, b) =>
                      a.attributes.orderInTheRow - b.attributes.orderInTheRow
                  )
                  .map((item, index) => (
                    <Hero.StyledLogoItem key={index}>
                      <img
                        src={item.attributes.logo.data.attributes.url}
                        alt={item.attributes.companyName}
                      />
                    </Hero.StyledLogoItem>
                  ))}
                {filteredRow(1)
                  .sort(
                    (a, b) =>
                      a.attributes.orderInTheRow - b.attributes.orderInTheRow
                  )
                  .map((item, index) => (
                    <Hero.StyledLogoItem key={index}>
                      <img
                        src={item.attributes.logo.data.attributes.url}
                        alt={item.attributes.companyName}
                      />
                    </Hero.StyledLogoItem>
                  ))}
              </div>
            </Hero.StyledLogos>
            <Hero.StyledLogos className="reverse">
              <div>
                {filteredRow(2)
                  .sort(
                    (a, b) =>
                      a.attributes.orderInTheRow - b.attributes.orderInTheRow
                  )
                  .map((item, index) => (
                    <Hero.StyledLogoItem key={index}>
                      <img
                        src={item.attributes.logo.data.attributes.url}
                        alt={item.attributes.companyName}
                      />
                    </Hero.StyledLogoItem>
                  ))}
                {filteredRow(2)
                  .sort(
                    (a, b) =>
                      a.attributes.orderInTheRow - b.attributes.orderInTheRow
                  )
                  .map((item, index) => (
                    <Hero.StyledLogoItem key={index}>
                      <img
                        src={item.attributes.logo.data.attributes.url}
                        alt={item.attributes.companyName}
                      />
                    </Hero.StyledLogoItem>
                  ))}
              </div>
            </Hero.StyledLogos>

            <p>{t('home-v2.hero.many-more')}</p>
          </Stack>
        </Hero.Customers>
      </PageContentContainer>
    </Hero.Root>
  );
};

export default HomeV2Hero;
