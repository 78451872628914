import styled from 'styled-components';
import { fontSize, screen } from 'theme';

const Root = styled.div`
  background-color: #fff;
  color: #433f52;
  padding: 1.5rem;
  font-size: ${fontSize('md')};
  border-radius: 2.5rem;

  @media (min-width: ${screen('md')}) {
    padding: 4rem;
  }
`;

export const CardHighlight03Styles = {
  Root
};
