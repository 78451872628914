import styled from 'styled-components';
import { fontSize } from 'theme';

const Root = styled.div`
  background-color: #ebd0f4;
  color: #433f52;
  padding: 2rem;
  font-size: ${fontSize('md')};
  border-radius: 24px;
`;

export const CardHighlight02Styles = {
  Root
};
