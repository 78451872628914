import { Header, Icon, Stack, StackItem } from '@asgard/ui-library';
import {
  faBuilding,
  faCalendar,
  faMegaphone,
  faSmile,
  faThumbsUp,
  faUsers
} from '@fortawesome/pro-light-svg-icons';
import { CardHighlight02 } from 'components/CardHighlight02/CardHighlight02';
import { CardHighlight03 } from 'components/CardHighlight03/CardHighlight03';
import { PageContentContainer } from 'components/layout/Page.styled';
import HtmlTrans from 'components/shared/HtmlTrans';
import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/router';
import { getPublicPath } from 'utils/paths';
import { Features } from './HomeV2Features.styled';
import { HomeV2FeaturesProps } from './HomeV2Features.types';

export const HomeV2Features: React.FC<HomeV2FeaturesProps> = ({
  manfreditesCount,
  stats
}) => {
  const { t } = useI18n('home-v2');
  const { locale } = useRouter();

  const currentStats = stats[0];

  const data = [
    {
      value: manfreditesCount.toLocaleString(locale),
      key: t('home-v2.features.data.data-01'),
      icon: faUsers
    },
    {
      value: currentStats?.attributes
        ? currentStats.attributes.successRate + '%'
        : null,
      key: t('home-v2.features.data.data-02'),
      icon: faThumbsUp
    },
    {
      value: currentStats?.attributes
        ? currentStats.attributes.timeToHire
        : null,
      key: t('home-v2.features.data.data-03'),
      append: t('home-v2.features.data.days'),
      icon: faCalendar
    },
    {
      value: currentStats?.attributes
        ? '+' + currentStats.attributes.positionsCount.toLocaleString(locale)
        : null,
      key: t('home-v2.features.data.data-04'),
      icon: faMegaphone
    },
    {
      value: currentStats?.attributes
        ? currentStats.attributes.customerScore.toLocaleString(locale)
        : null,
      key: t('home-v2.features.data.data-05'),
      icon: faSmile
    },
    {
      value: currentStats?.attributes
        ? '+' + currentStats.attributes.companiesCount.toLocaleString(locale)
        : null,
      key: t('home-v2.features.data.data-06'),
      icon: faBuilding
    }
  ];

  return (
    <Features.Root>
      <PageContentContainer containerSize="xl">
        <Features.Intro>
          <p>{t('home-v2.features.what.subtitle')}</p>
          <Header editorial as="h2" size="5xl">
            {t('home-v2.features.what.title')}
          </Header>
        </Features.Intro>

        <Stack direction="column" gap="3xl">
          {Array.from({ length: 3 }).map((_, index) => (
            <CardHighlight03 key={index}>
              <Features.WhatCards>
                <img
                  loading="lazy"
                  src={getPublicPath(
                    `/images/home-v2/home-v2-features-what-card-0${
                      index + 1
                    }.webp`
                  )}
                  alt=""
                />

                <StackItem>
                  <h3>{t(`home-v2.features.what.card-0${index + 1}.title`)}</h3>
                  <HtmlTrans
                    ns="home-v2"
                    i18nKey={`home-v2.features.what.card-0${index + 1}.desc`}
                  />
                </StackItem>
              </Features.WhatCards>
            </CardHighlight03>
          ))}
        </Stack>
      </PageContentContainer>

      <PageContentContainer containerSize="xl">
        <Features.Intro>
          <p>{t('home-v2.features.talk.subtitle')}</p>
          <Header editorial as="h2" size="5xl">
            {t('home-v2.features.talk.title')}
          </Header>
        </Features.Intro>

        <CardHighlight02>
          <Stack direction="column">
            <img
              loading="lazy"
              src={getPublicPath(
                `/images/home-v2/home-v2-features-talk-card-01.webp`
              )}
              alt=""
              className="lg"
            />

            <img
              loading="lazy"
              className="md"
              src={getPublicPath(
                `/images/home-v2/home-v2-features-talk-card-01-md.webp`
              )}
              alt=""
            />

            <Features.TalkCards align="center">
              <StackItem>
                <HtmlTrans
                  ns="home-v2"
                  i18nKey={`home-v2.features.talk.card-01.desc`}
                />
              </StackItem>
            </Features.TalkCards>
          </Stack>
        </CardHighlight02>
      </PageContentContainer>

      <PageContentContainer containerSize="xl">
        <Features.Intro>
          <p>{t('home-v2.features.data.subtitle')}</p>
          <Header editorial as="h2" size="5xl">
            {t('home-v2.features.data.title')}
          </Header>
        </Features.Intro>

        <Features.DataStack>
          {data.map(({ key, value, append, icon }, index) => (
            <Features.DataItem key={index}>
              <Icon icon={icon} size="6xl" />

              <Stack direction="column" gap="xs">
                <strong>
                  {value}
                  {!!append && <span>{append}</span>}
                </strong>
                <span>{key}</span>
              </Stack>
            </Features.DataItem>
          ))}
        </Features.DataStack>
      </PageContentContainer>
    </Features.Root>
  );
};
