import { CardHighlight02Styles } from './CardHighlight02.styled';

export const CardHighlight02: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({ children, ...rest }) => {
  return (
    <CardHighlight02Styles.Root {...rest}>
      {children}
    </CardHighlight02Styles.Root>
  );
};
