import styled from 'styled-components';
import { container, fontSize, screen } from 'theme';

const Root = styled.div`
  background-color: #faf9ff;
  color: #433f52;
  padding: 24px 1rem;
  font-size: ${fontSize('lg')};
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (min-width: ${screen('lg')}) {
    padding: 64px 0;
  }

  img {
    display: block;
    margin: 0 auto;

    &.md {
      display: block;
    }

    &.lg {
      display: none;
    }

    @media (min-width: ${screen('lg')}) {
      &.md {
        display: none;
      }

      &.lg {
        display: block;
      }
    }
  }
`;

const Intro = styled.div`
  max-width: ${container('lg')};
  margin: 0 auto 3rem auto;

  h2 {
    margin-top: 0;
    font-size: ${fontSize('xl2')};
    text-wrap: balance;
  }

  p {
    font-size: ${fontSize('md')};
    line-height: 1.75;
    max-width: ${container('sm')};
    margin: 0 auto;
  }

  p:first-child {
    font-size: ${fontSize('lg')};
    font-weight: 800;
    margin-bottom: 0;
  }

  p:last-child {
    margin: 0 auto;
  }

  @media (min-width: ${screen('lg')}) {
    text-align: center;

    h2 {
      font-size: ${fontSize('xl4')};
    }
  }
`;

const WhatCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  line-height: 1.75;
  font-size: ${fontSize('md')};

  h3 {
    font-size: ${fontSize('lg')};
    line-height: 1.25;
  }

  img {
    max-width: 100%;
    margin: 0 auto;
  }

  @media (min-width: ${screen('lg')}) {
    flex-direction: row;
    align-items: center;
    gap: 4rem;
    font-size: ${fontSize('lg')};

    h3 {
      font-size: ${fontSize('xl3')};
    }

    img {
      max-width: 40%;
    }
  }
`;

const TalkCards = styled.div<{ align?: 'left' | 'center' | 'right' }>`
  font-size: ${fontSize('lg')};
  line-height: 1.75;

  h3 {
    margin: 0 0 0.5rem 0;
    font-size: ${fontSize('xl')};
    font-weight: 700;
    line-height: 1.25;
  }

  p {
    margin-top: 0;
    font-size: ${fontSize('md')};
  }

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${screen('lg')}) {
    max-width: ${({ align }) => (align === 'center' ? '55%' : '100%')};
    text-align: ${({ align }) => align || 'left'};
    margin: 0 auto;

    h3 {
      font-size: ${fontSize('xl3')};
    }

    p {
      font-size: ${fontSize('lg')};
    }
  }
`;

const HowCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: ${screen('lg')}) {
    flex-direction: row;
  }
`;

const HowCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  line-height: 1.75;
  font-size: ${fontSize('md')};

  img {
    max-width: 100%;
  }

  h3 {
    font-size: ${fontSize('lg')};
    line-height: 1.25;
    color: #092c61;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('lg')};

    h3 {
      font-size: ${fontSize('xl3')};
    }
  }
`;

const DataStack = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;

  @media (min-width: ${screen('md')}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${screen('lg')}) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }
`;

const DataItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: flex-start;

  svg {
    color: #c082d1;
    max-height: 40px;
  }

  strong {
    line-height: 1;
    font-weight: 900;
    font-size: ${fontSize('xl2')};
    margin-bottom: 0;

    span {
      font-weight: 400;
      font-size: ${fontSize('xl')};
    }
  }

  span {
    font-size: ${fontSize('md')};
  }

  @media (min-width: ${screen('lg')}) {
    strong {
      font-size: ${fontSize('xl4')};
    }

    span {
      font-size: ${fontSize('lg')};
    }

    svg {
      max-height: 64px;
    }
  }
`;

export const Features = {
  Root,
  Intro,
  WhatCards,
  TalkCards,
  HowCardsWrapper,
  HowCards,
  DataItem,
  DataStack
};
