import Page from 'components/layout/Page';
import dynamic from 'next/dynamic';
import { HomeV2PageProps } from './HomeV2Page.types';
import { HomeV2Features } from './features/HomeV2Features';
import HomeV2Hero from './hero/HomeV2Hero';

export const HomeV2Form = dynamic(() => import('./form/HomeV2Form'), {
  ssr: false
});

export const HomeV2Community = dynamic(
  () => import('./community/HomeV2Community'),
  {
    ssr: false
  }
);

export const HomeV2Page: React.FC<HomeV2PageProps> = ({
  bannerText,
  manfreditesCount,
  stats,
  customers
}) => {
  return (
    <Page hasScrollTop pageVariant="landing" bannerText={bannerText}>
      <HomeV2Hero customers={customers} />
      <HomeV2Features manfreditesCount={manfreditesCount} stats={stats} />
      <HomeV2Community />
      <HomeV2Form />
    </Page>
  );
};
