import { CardHighlight03Styles } from './CardHighlight03.styled';

export const CardHighlight03: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({ children, ...rest }) => {
  return (
    <CardHighlight03Styles.Root {...rest}>
      {children}
    </CardHighlight03Styles.Root>
  );
};
