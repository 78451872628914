import HeadMetadata from 'components/shared/HeadMetadata';
import { HomeV2Page } from 'features/home-v2/HomeV2Page';
import { HomeV2PageProps } from 'features/home-v2/HomeV2Page.types';
import { useI18n } from 'hooks/useI18n';
import { usePageTrack } from 'hooks/usePageTrack';
import { WithBaseUrl } from 'interfaces/ui/with-base-url';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { ServiceServicePublicStats } from 'services/service.public-stats';
import { ServiceStrapi } from 'services/service.strapi';
import { APP_URLS } from 'utils/app-urls';
import { config } from 'utils/config';
import { getPublicPath } from 'utils/paths';

type LandingPageProps = WithBaseUrl & HomeV2PageProps;

const LandingPage: React.FC<LandingPageProps> = ({
  baseUrl,
  bannerText,
  manfreditesCount,
  stats,
  customers
}) => {
  const { t } = useI18n('home-v2');

  usePageTrack('Website - Page Loaded');

  return (
    <>
      <HeadMetadata
        title={t('home-v2.page_title')}
        desc={t('home-v2.page_description')}
        url={APP_URLS.home}
        imgUrl={`${baseUrl}${getPublicPath('/images/cards/card_homeV2.png')}`}
      />
      <HomeV2Page
        bannerText={bannerText}
        manfreditesCount={manfreditesCount}
        stats={stats}
        customers={customers}
      />
    </>
  );
};

export default LandingPage;

const parseJSON = (resp: any): any => (resp.json ? resp.json() : resp);

const checkStatus = (resp: any): any => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then((resp: any) => {
    throw resp;
  });
};

export const getStaticProps: GetStaticProps<LandingPageProps> = async ({
  locale
}) => {
  const [
    { manfreditesCount },
    {
      data: {
        attributes: { text: bannerText }
      }
    },
    stats,
    customers
  ] = await Promise.all([
    ServiceServicePublicStats.get(),
    ServiceStrapi.get(`/talent-web-banner?locale=${locale}`),
    fetch(`https://cms.getmanfred.com/api/companies-page-stats`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'GET'
    })
      .then(checkStatus)
      .then(parseJSON),
    fetch(
      `https://cms.getmanfred.com/api/companies-page-customers?populate=%2A&pagination[pageSize]=100`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET'
      }
    )
      .then(checkStatus)
      .then(parseJSON)
  ]);

  return {
    props: {
      ...(await serverSideTranslations(locale || '', [
        'common',
        'topbar',
        'footer',
        'home-v2'
      ])),
      baseUrl: config.appUrl,
      bannerText: bannerText || '',
      manfreditesCount,
      stats: stats.data,
      customers: customers.data
    },
    revalidate: 6 * 60 // 5 minutes
  };
};
