import styled from 'styled-components';
import { fontSize, screen, spacing } from 'theme';

const Root = styled.div`
  background-color: #d7edf1;
  color: #092c61;
  padding: 24px 0;
  font-size: ${fontSize('lg')};

  .titles {
    flex-direction: column-reverse;
  }
`;

const Wrapper = styled.div`
  background: url('/images/home-v2/home-v2-hero-bg.svg') no-repeat top center;
  background-size: cover;
  padding: 3rem;

  text-align: center;
  color: #fff;

  font-weight: 800;

  p {
    margin: 0;
  }

  img {
    max-width: 95%;
    margin: 0 auto;
  }

  h2 {
    font-size: ${fontSize('xl3')} !important;
    font-weight: 800;
    text-wrap: balance;
  }

  @media (min-width: ${screen('lg')}) {
    padding: 100px 14rem;
    background-size: contain;

    h2 {
      font-size: ${fontSize('xl5')} !important;
      font-weight: 800;
    }
  }
`;

const Customers = styled.div`
  text-align: center;
  font-size: ${fontSize('md')};

  h3 {
    font-weight: 800;
    text-transform: uppercase;
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('lg')};
  }
`;

export const StyledLogos = styled.div`
  height: ${spacing('xl')};
  overflow: hidden;
  position: relative;

  @media (min-width: ${screen('lg')}) {
    height: ${spacing('xl3')};
  }

  &::before,
  &::after {
    content: '';
    height: ${spacing('xl3')};
    position: absolute;
    width: ${spacing('xl5')};
    z-index: 2;
    color: rgb(215, 237, 241);
    background: linear-gradient(
      to right,
      rgba(215, 237, 241, 1) 0%,
      rgba(250, 249, 255, 0) 100%
    );
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  > div {
    animation: scroll 40s linear infinite;
    display: flex;
    gap: ${spacing('xl5')};
  }

  &.reverse > div {
    animation-direction: reverse;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

export const StyledLogoItem = styled.div`
  flex-shrink: 0;
  height: ${spacing('xl')};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${screen('lg')}) {
    height: ${spacing('xl3')};
  }

  img {
    height: ${spacing('mdlg')};
    width: auto;
    display: block;
    filter: grayscale(100%);

    @media (min-width: ${screen('lg')}) {
      height: ${spacing('xl2')};
    }
  }
`;

export const Hero = {
  Root,
  Wrapper,
  Customers,
  StyledLogos,
  StyledLogoItem
};
